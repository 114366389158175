/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react"
import "../assets/styles/components/articleCard.scss"

const ArticleCard = (props: { articleImage: any; articleHeadtext: any; articleSubtext: any; meta: any }) => {
  const { articleImage, articleHeadtext, articleSubtext, meta } = props

  return (
    <div className="article--wrapper">
      <div className="article--image--wrapper">
        <img src={articleImage} alt="" />
      </div>
      <div className="article--text--wrapper">
        <div className="article--text--inner">
          <p className="head--text--sub">
            <span className="head--text--bold">{articleHeadtext}</span>
            {articleSubtext}
          </p>
        </div>
        <div className="meta">
          <p>{meta}</p>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
