import React, { useEffect, useState } from "react"
import { debounce } from "lodash"
import "../../../assets/styles/page/docSearch.scss"
import _ from "lodash"
import ArticleCard from "../../../components/ArticleCard"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/layout"

const Docsearch = ({ data }) => {
  const [query, setQuery] = useState("")
  const [items] = useState(
    [...data?.allContentfulArticle?.nodes, ...data?.allContentfulProject?.nodes, ...data.allContentfulVideo.nodes] || []
  )
  const [newList, setNewList] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<
    Array<{
      title: string
      subtitle: string
      category: string
      image: string
      id: string
      slug: string
    }>
  >([])

  //////////// filtering function ///////////
  const getFilteredContent = (query: string, newList: any[]) => {
    if (!query) {
      return newList
    }

    const newFilteredArray = newList.filter(
      t => t.title.toLowerCase().includes(query.toLowerCase()) || t.subtitle.toLowerCase().includes(query.toLowerCase())
    )
    return newFilteredArray
  }
  ////////
  const getFilteredArticles = () => {
    const newFilteredArticle = newList.filter(t => t.contentCategory.includes("article"))
    setFilteredItems(newFilteredArticle)
  }

  const getFilteredProject = () => {
    const newFilteredProject = newList.filter(t => t.contentCategory.includes("project"))
    setFilteredItems(newFilteredProject)
  }

  const getFilteredVideo = () => {
    const newFilteredVideo = newList.filter(t => t.contentCategory.includes("video"))

    setFilteredItems(newFilteredVideo)
  }

  const getAll = () => {
    setFilteredItems(newList)
  }

  ////////// debounce function ////////////////
  const filterArticles = debounce(function (e) {
    setFilteredItems(getFilteredContent(e, newList))
  }, 100)

  const handleChange = (e: any) => {
    setQuery(e.target.value)
    filterArticles(e.target.value)
  }

  useEffect(() => {
    const lists = items.map(article => ({
      title: `${article?.titleOfArticle || article?.titleOfProject || article.videoTitle}`,
      image: `${article.bannerUrl?.fluid?.src || article.videoThumbnail?.fluid?.src}`,
      subtitle: `${article.metaTitle || article.metaTitle || article.projectDescription}`,
      contentCategory: article.contentCategory,
      category: `${article.metaDescription || article.videoCategory || article.ProjectCategory}`,
      id: `${article.contentful_id}`,
      slug:
        article.contentCategory === "article"
          ? `/doc/article/doc-article/${article.slug}`
          : article.contentCategory === "project"
          ? `/doc/project/doc-projects/${article.slug}`
          : article.contentCategory === "video"
          ? `/doc/article/doc-videos/${article.slug}`
          : ""
    }))
    setFilteredItems(lists)
    setNewList(lists)
  }, [])

  return (
    <>
      <Layout>
        <div className="search--page--container">
          <div className="search--input--wrapper">
            <div className="search--input--icon">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#2b2b2b"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0004 20.9999L16.6504 16.6499"
                  stroke="#2b2b2b"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input type="search" className="search--input" placeholder="Search" value={query} onChange={handleChange} />
          </div>
          <div className="search--selection">
            <div onClick={getAll} className="search--option">
              All
            </div>
            <div onClick={getFilteredArticles} className="search--option">
              Article
            </div>
            <div onClick={getFilteredProject} className="search--option">
              Project
            </div>
            <div onClick={getFilteredVideo} className="search--option">
              Videos
            </div>
          </div>
          <div className="searching--items--containers">
            {filteredItems.map(value => {
              const { id, title, category, image, subtitle, slug } = value
              return (
                <Link to={slug} className="search--artile--card--wrapper" key={id}>
                  <ArticleCard
                    key={id}
                    articleImage={image}
                    articleHeadtext={title}
                    articleSubtext={subtitle}
                    meta={category}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Docsearch

export const query = graphql`
  query articleListAndprojectlistAndVideoList {
    allContentfulArticle {
      nodes {
        titleOfArticle
        metaTitle
        metaDescription
        contentful_id
        contentCategory
        slug
        bannerUrl {
          fluid {
            src
          }
        }
      }
    }

    allContentfulProject {
      nodes {
        bannerUrl {
          fluid {
            src
          }
        }
        contentful_id
        titleOfProject
        projectDescription
        ProjectCategory
        contentCategory
        slug
      }
    }

    allContentfulVideo {
      nodes {
        videoCategory
        videoTitle
        contentCategory
        slug
        contentful_id
        videoThumbnail {
          fluid {
            src
          }
        }
        metaTitle
      }
    }
  }
`
